export const mapsLinks= [
  {
      "name": "Pcp",
      "url": "https://lh3.googleusercontent.com/d/1Facx-CotYkPGq46nJFAkzKgaWpXRMQ-D",
      "label":"Observed and Forecast Rainfall Conditions",
      "section": "Rainfall Conditions",
  },
  {
      "name": "ET",
      "url": "https://lh3.googleusercontent.com/d/1cpfCYNkiQtMAVkeoauJiCe1K-0E2bWra",
      "label":"Observed and Forecast Evapotranspiration",
      "section": "Evapotranspiration",
  },
  {
      "name": "T",
      "url": "https://lh3.googleusercontent.com/d/1ojPESyiyKI-Rdln7vISvxbu560M7Y7fa",
      "label":"Observed and Forecast Surface Air Temperature",
      "section": "Surface Air Temperature",
  },
  {
      "name": "Q",
      "url": "https://lh3.googleusercontent.com/d/1tWqzalSbfoHDYC0q0aiYf5wd4Y_rraOi",
      "label":"Observed and Forecast Streamflow at Stream Network",
      "section": "Streamflow at Stream Network",
  },
  {
      "name": "sm",
      "url": "https://lh3.googleusercontent.com/d/1DN9OvqKQNU6zh_Ooa0l9dm257Cj-Q2rS",
      "label":"Observed and Forecast Relative Wetness",
      "section": "Relative Wetness",
  },
  {
      "name": "Station_Q",
      "url": "https://lh3.googleusercontent.com/d/1VyFP4HaK5iF74W6uaj7ILWelaNXbDdlr",
      "label":"Observed and Forecast Streamflow at Gauge Stations",
      "section": "Streamflow at Gauge Stations",
  },
  {
      "name": "ro",
      "url": "https://lh3.googleusercontent.com/d/1vuUBE8PjZaMgsGW3Iu56h5nXcZCTrX3w",
      "label":"Observed and Forecast Total Runoff",
      "section": "Total Runoff",
  }
]